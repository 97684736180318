import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Dimmer,
  Loader,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import "./App.css";
import { useReactPWAInstall } from "react-pwa-install";
import { iso639 } from "./iso639-1";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const languageOptions = Object.entries(iso639).map((item) => {
  return {
    key: item[0],
    value: item[0],
    text: item[1].name,
  };
}).sort((a, b) => a.text.localeCompare(b.text));

const raceOptions = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White",
  "Other",
  "Choose not to disclose",
  "Unknown",
].map((k) => {
  return { key: k, value: k, text: k };
});

const ethnicityOptions = [
  "Hispanic or Latino",
  "Not Hispanic or Latino",
  "Other",
  "Choose not to disclose",
  "Unknown",
].map((k) => {
  return { key: k, value: k, text: k };
});

const genderIdentityOptions = [
  "Male",
  "Female",
  "Female-to-Male (FTM)/Transgender Male/Trans Man",
  "Male-to-Female (MTF)/Transgender Female/Trans Woman",
  "Genderqueer, neither exclusively male nor female",
  "Other",
  "Choose not to disclose",
].map((k) => {
  return { key: k, value: k, text: k };
});

const sexualOrientationOptions = [
  "Straight or heterosexual",
  "Lesbian, gay, or homosexual",
  "Bisexual",
  "Don't Know",
  "Other",
  "Choose not to disclose",
].map((k) => {
  return { key: k, value: k, text: k };
});

const CheckIn = (props) => {
  let query = useQuery();
  const [mbi, setMbi] = useState(query.get("mbi") || "");
  const [validMbi, setValidMbi] = useState(false);
  const [language, setLanguage] = useState("en");
  const [race, setRace] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [genderIdentity, setGenderIdentity] = useState('');
  const [genderIdentityFT, setGenderIdentityFT] = useState('');
  const [sexualOrientation, setSexualOrientation] = useState('');
  const [sexualOrientationFT, setSexualOrientationFT] = useState('');
  const [inProcess, setInProcess] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [optOutAnswered, setOptOutAnswered] = useState(false);
  const [optOutReason, setOptOutReason] = useState(
    "The patient declined to participate in the survey."
  );
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const handleSubmit = async (event) => {
    setInProcess(true);
    const doc = {
      mbi: mbi,
      language: language,
      race: (race === '')?'Choose not to disclose':race,
      ethnicity: (ethnicity === '')?'Choose not to disclose':ethnicity,
      genderIdentity: (genderIdentity === '')?'Choose not to disclose':genderIdentity,
      genderIdentityFT: genderIdentityFT,
      sexualOrientation: (sexualOrientation === '')?'Choose not to disclose':sexualOrientation,
      sexualOrientationFT: sexualOrientationFT,
      optOutReason: optOutReason,
      version: '0.0.1',
      recordDatetime: new Date().toISOString(),
    };

    const rawResponse = await fetch(
      "https://data.mongodb-api.com/app/awv-simple-checkin-hcopc/endpoint/submit_survey",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(doc),
      }
    );

    setInProcess(false);
    setCompleted(true);
  };

  const handleInstall = () => {
    pwaInstall({
      title: "Install Patient Portal",
      logo: "android-chrome-192x192.png",
      features: (
        <ul>
          <li>No Scan and Fax</li>
          <li>Easy Submit</li>
        </ul>
      ),
      description: "This app helps you keep track of your health!",
    })
      .then(() =>
        alert("App installed successfully or instructions for install shown")
      )
      .catch(() => alert("User opted out from installing"));
  };

  return (
    <div
      style={{
        backgroundImage: 'url("images/login-bg.jpg")',
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Dimmer inverted active={inProcess}>
        <Loader />
      </Dimmer>
      <Grid
        textAlign="left"
        style={{
          height: "100vh",
          minHeight: "1000px",
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.75)",
        }}
        verticalAlign="top"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            padding: "3em",
          }}
          className="container demo animated"
        >
          <Header as="h1" icon textAlign="left" style={{ color: "#fff" }}>
            <Header.Content>
              APA ACO Demographic Survey
            </Header.Content>
          </Header>
          <Divider/>
          {!optOutAnswered && (
            <>
              <Header as="h1" icon textAlign="left" style={{ color: "#fff" }}>
                <Header.Subheader style={{ color: "#fff", fontSize: "0.75em" }}>
                  Before proceeding, please have your Medicare Beneficiary ID (11 digits) ready. Thanks so much! 
                </Header.Subheader>
              </Header>
              <img style={{width: '80%'}} src="/images/mbi_example.png"/>
              <Divider />
              <Form size="large" style={{ color: "#fff" }}>
                <label>Medicare Beneficiary ID without "-" (dash)</label>
                <input
                  value={mbi}
                  placeholder='1EG4TE5MK72'
                  onChange={(event)=>{
                    let mbiTmp = event.target.value
                                    .replace(/[^\w\s]/gi, '')
                                    .toUpperCase()
                                    .substring(0,11);
                    setMbi(mbiTmp);
                    setValidMbi(mbiTmp.length===11);
                  }} 
                />
                <Divider/>  
                <label>Preferred Language</label>
                <Dropdown
                  value={language}
                  fluid
                  search
                  selection
                  options={languageOptions}
                  onChange={(event, { value }) => {
                    setLanguage(value);
                  }}
                />
                <Divider/>    
              </Form>
              <Button
                disabled={!validMbi}
                primary
                fluid
                size="large"
                onClick={() => {
                  setOptOutReason("Opted-In");
                  setOptOutAnswered(true);
                }}
              >
                Start the Survey!
              </Button>
              <Divider />
              <Button
                disabled={!validMbi}
                secondary
                fluid
                size="large"
                onClick={() => {
                  setOptOutAnswered(true);
                  handleSubmit();
                }}
              >
                Opt out from the survey.
              </Button>
            </>
          )}
          {optOutAnswered && (
            <>
              {!completed && (
                <Form size="large" style={{ color: "#fff" }}>
                  <Header style={{ color: "#fff" }}>
                    Medicare ID: {mbi}
                  </Header>
                  <label>Race</label>
                  <Dropdown
                    value={race}
                    placeholder='e.g. White'
                    fluid
                    search
                    selection
                    options={raceOptions}
                    onChange={(event, { value }) => {
                      setRace(value);
                    }}
                  />
                  <Divider />
                  <label>Ethnicity</label>
                  <Dropdown
                    value={ethnicity}
                    placeholder='e.g. Hispanic or Latino'
                    fluid
                    search
                    selection
                    options={ethnicityOptions}
                    onChange={(event, { value }) => {
                      setEthnicity(value);
                    }}
                  />
                  <Divider />
                  <label>Gender Identity (optional)</label>
                  <Dropdown
                    value={genderIdentity}
                    placeholder='e.g. Straight or heterosexual'
                    fluid
                    search
                    selection
                    options={genderIdentityOptions}
                    onChange={(event, { value }) => {
                      setGenderIdentity(value);
                    }}
                  />
                  {genderIdentity === 'Other' &&
                    <input
                      value={genderIdentityFT}
                      placeholder='If answered "Other", please describe.'
                      onChange={(event)=>{
                        setGenderIdentityFT(event.target.value);
                      }} 
                    />
                  }
                  <Divider />
                  <label>Sexual Orientation (optional)</label>
                  <Dropdown 
                    value={sexualOrientation}
                    placeholder='e.g. Male'
                    fluid
                    search
                    selection
                    options={sexualOrientationOptions}
                    onChange={(event, { value }) => {
                      setSexualOrientation(value);
                    }}
                  />
                  {sexualOrientation ==='Other' &&
                  <input
                    value={sexualOrientationFT}
                    placeholder='If answered "Other", please describe.'
                    onChange={(event)=>{
                      setSexualOrientationFT(event.target.value);
                    }} 
                  />}
                </Form>
              )}
              <Divider />
              {!completed && (
                <Button
                  //style={{backgroundColor: "#335eea", color: "#fff"}}
                  //color="green"
                  primary
                  fluid
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              )}
              {completed && (
                <Header
                  as="h1"
                  icon
                  textAlign="center"
                  style={{ color: "#fff" }}
                >
                  Thanks for completing the survey!
                </Header>
              )}
            </>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default CheckIn;
