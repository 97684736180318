export var iso639 = {
  aa: {
    "639-1": "aa",
    "639-2": "aar",
    family: "Afro-Asiatic",
    name: "Afar",
    nativeName: "Afaraf",
    wikiUrl: "https://en.wikipedia.org/wiki/Afar_language",
  },
  ab: {
    "639-1": "ab",
    "639-2": "abk",
    family: "Northwest Caucasian",
    name: "Abkhaz",
    nativeName: "аҧсуа бызшәа, аҧсшәа",
    wikiUrl: "https://en.wikipedia.org/wiki/Abkhaz_language",
  },
  ae: {
    "639-1": "ae",
    "639-2": "ave",
    family: "Indo-European",
    name: "Avestan",
    nativeName: "avesta",
    wikiUrl: "https://en.wikipedia.org/wiki/Avestan_language",
  },
  af: {
    "639-1": "af",
    "639-2": "afr",
    family: "Indo-European",
    name: "Afrikaans",
    nativeName: "Afrikaans",
    wikiUrl: "https://en.wikipedia.org/wiki/Afrikaans_language",
  },
  ak: {
    "639-1": "ak",
    "639-2": "aka",
    family: "Niger–Congo",
    name: "Akan",
    nativeName: "Akan",
    wikiUrl: "https://en.wikipedia.org/wiki/Akan_language",
  },
  am: {
    "639-1": "am",
    "639-2": "amh",
    family: "Afro-Asiatic",
    name: "Amharic",
    nativeName: "አማርኛ",
    wikiUrl: "https://en.wikipedia.org/wiki/Amharic_language",
  },
  an: {
    "639-1": "an",
    "639-2": "arg",
    family: "Indo-European",
    name: "Aragonese",
    nativeName: "aragonés",
    wikiUrl: "https://en.wikipedia.org/wiki/Aragonese_language",
  },
  ar: {
    "639-1": "ar",
    "639-2": "ara",
    family: "Afro-Asiatic",
    name: "Arabic",
    nativeName: "العربية",
    wikiUrl: "https://en.wikipedia.org/wiki/Arabic_language",
  },
  as: {
    "639-1": "as",
    "639-2": "asm",
    family: "Indo-European",
    name: "Assamese",
    nativeName: "অসমীয়া",
    wikiUrl: "https://en.wikipedia.org/wiki/Assamese_language",
  },
  av: {
    "639-1": "av",
    "639-2": "ava",
    family: "Northeast Caucasian",
    name: "Avaric",
    nativeName: "авар мацӀ, магӀарул мацӀ",
    wikiUrl: "https://en.wikipedia.org/wiki/Avar_language",
  },
  ay: {
    "639-1": "ay",
    "639-2": "aym",
    family: "Aymaran",
    name: "Aymara",
    nativeName: "aymar aru",
    wikiUrl: "https://en.wikipedia.org/wiki/Aymara_language",
  },
  az: {
    "639-1": "az",
    "639-2": "aze",
    family: "Turkic",
    name: "Azerbaijani",
    nativeName: "azərbaycan dili",
    wikiUrl: "https://en.wikipedia.org/wiki/Azerbaijani_language",
  },
  ba: {
    "639-1": "ba",
    "639-2": "bak",
    family: "Turkic",
    name: "Bashkir",
    nativeName: "башҡорт теле",
    wikiUrl: "https://en.wikipedia.org/wiki/Bashkir_language",
  },
  be: {
    "639-1": "be",
    "639-2": "bel",
    family: "Indo-European",
    name: "Belarusian",
    nativeName: "беларуская мова",
    wikiUrl: "https://en.wikipedia.org/wiki/Belarusian_language",
  },
  bg: {
    "639-1": "bg",
    "639-2": "bul",
    family: "Indo-European",
    name: "Bulgarian",
    nativeName: "български език",
    wikiUrl: "https://en.wikipedia.org/wiki/Bulgarian_language",
  },
  bh: {
    "639-1": "bh",
    "639-2": "bih",
    family: "Indo-European",
    name: "Bihari",
    nativeName: "भोजपुरी",
    wikiUrl: "https://en.wikipedia.org/wiki/Bihari_languages",
  },
  bi: {
    "639-1": "bi",
    "639-2": "bis",
    family: "Creole",
    name: "Bislama",
    nativeName: "Bislama",
    wikiUrl: "https://en.wikipedia.org/wiki/Bislama_language",
  },
  bm: {
    "639-1": "bm",
    "639-2": "bam",
    family: "Niger–Congo",
    name: "Bambara",
    nativeName: "bamanankan",
    wikiUrl: "https://en.wikipedia.org/wiki/Bambara_language",
  },
  bn: {
    "639-1": "bn",
    "639-2": "ben",
    family: "Indo-European",
    name: "Bengali, Bangla",
    nativeName: "বাংলা",
    wikiUrl: "https://en.wikipedia.org/wiki/Bengali_language",
  },
  bo: {
    "639-1": "bo",
    "639-2": "bod",
    "639-2/B": "tib",
    family: "Sino-Tibetan",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
    wikiUrl: "https://en.wikipedia.org/wiki/Standard_Tibetan",
  },
  br: {
    "639-1": "br",
    "639-2": "bre",
    family: "Indo-European",
    name: "Breton",
    nativeName: "brezhoneg",
    wikiUrl: "https://en.wikipedia.org/wiki/Breton_language",
  },
  bs: {
    "639-1": "bs",
    "639-2": "bos",
    family: "Indo-European",
    name: "Bosnian",
    nativeName: "bosanski jezik",
    wikiUrl: "https://en.wikipedia.org/wiki/Bosnian_language",
  },
  ca: {
    "639-1": "ca",
    "639-2": "cat",
    family: "Indo-European",
    name: "Catalan",
    nativeName: "català",
    wikiUrl: "https://en.wikipedia.org/wiki/Catalan_language",
  },
  ce: {
    "639-1": "ce",
    "639-2": "che",
    family: "Northeast Caucasian",
    name: "Chechen",
    nativeName: "нохчийн мотт",
    wikiUrl: "https://en.wikipedia.org/wiki/Chechen_language",
  },
  ch: {
    "639-1": "ch",
    "639-2": "cha",
    family: "Austronesian",
    name: "Chamorro",
    nativeName: "Chamoru",
    wikiUrl: "https://en.wikipedia.org/wiki/Chamorro_language",
  },
  co: {
    "639-1": "co",
    "639-2": "cos",
    family: "Indo-European",
    name: "Corsican",
    nativeName: "corsu, lingua corsa",
    wikiUrl: "https://en.wikipedia.org/wiki/Corsican_language",
  },
  cr: {
    "639-1": "cr",
    "639-2": "cre",
    family: "Algonquian",
    name: "Cree",
    nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
    wikiUrl: "https://en.wikipedia.org/wiki/Cree_language",
  },
  cs: {
    "639-1": "cs",
    "639-2": "ces",
    "639-2/B": "cze",
    family: "Indo-European",
    name: "Czech",
    nativeName: "čeština, český jazyk",
    wikiUrl: "https://en.wikipedia.org/wiki/Czech_language",
  },
  cu: {
    "639-1": "cu",
    "639-2": "chu",
    family: "Indo-European",
    name: "Old Church Slavonic, Church Slavonic, Old Bulgarian",
    nativeName: "ѩзыкъ словѣньскъ",
    wikiUrl: "https://en.wikipedia.org/wiki/Old_Church_Slavonic",
  },
  cv: {
    "639-1": "cv",
    "639-2": "chv",
    family: "Turkic",
    name: "Chuvash",
    nativeName: "чӑваш чӗлхи",
    wikiUrl: "https://en.wikipedia.org/wiki/Chuvash_language",
  },
  cy: {
    "639-1": "cy",
    "639-2": "cym",
    "639-2/B": "wel",
    family: "Indo-European",
    name: "Welsh",
    nativeName: "Cymraeg",
    wikiUrl: "https://en.wikipedia.org/wiki/Welsh_language",
  },
  da: {
    "639-1": "da",
    "639-2": "dan",
    family: "Indo-European",
    name: "Danish",
    nativeName: "dansk",
    wikiUrl: "https://en.wikipedia.org/wiki/Danish_language",
  },
  de: {
    "639-1": "de",
    "639-2": "deu",
    "639-2/B": "ger",
    family: "Indo-European",
    name: "German",
    nativeName: "Deutsch",
    wikiUrl: "https://en.wikipedia.org/wiki/German_language",
  },
  dv: {
    "639-1": "dv",
    "639-2": "div",
    family: "Indo-European",
    name: "Divehi, Dhivehi, Maldivian",
    nativeName: "ދިވެހި",
    wikiUrl: "https://en.wikipedia.org/wiki/Dhivehi_language",
  },
  dz: {
    "639-1": "dz",
    "639-2": "dzo",
    family: "Sino-Tibetan",
    name: "Dzongkha",
    nativeName: "རྫོང་ཁ",
    wikiUrl: "https://en.wikipedia.org/wiki/Dzongkha_language",
  },
  ee: {
    "639-1": "ee",
    "639-2": "ewe",
    family: "Niger–Congo",
    name: "Ewe",
    nativeName: "Eʋegbe",
    wikiUrl: "https://en.wikipedia.org/wiki/Ewe_language",
  },
  el: {
    "639-1": "el",
    "639-2": "ell",
    "639-2/B": "gre",
    family: "Indo-European",
    name: "Greek (modern)",
    nativeName: "ελληνικά",
    wikiUrl: "https://en.wikipedia.org/wiki/Greek_language",
  },
  en: {
    "639-1": "en",
    "639-2": "eng",
    family: "Indo-European",
    name: "English",
    nativeName: "English",
    wikiUrl: "https://en.wikipedia.org/wiki/English_language",
  },
  eo: {
    "639-1": "eo",
    "639-2": "epo",
    family: "Constructed",
    name: "Esperanto",
    nativeName: "Esperanto",
    wikiUrl: "https://en.wikipedia.org/wiki/Esperanto",
  },
  es: {
    "639-1": "es",
    "639-2": "spa",
    family: "Indo-European",
    name: "Spanish",
    nativeName: "Español",
    wikiUrl: "https://en.wikipedia.org/wiki/Spanish_language",
  },
  et: {
    "639-1": "et",
    "639-2": "est",
    family: "Uralic",
    name: "Estonian",
    nativeName: "eesti, eesti keel",
    wikiUrl: "https://en.wikipedia.org/wiki/Estonian_language",
  },
  eu: {
    "639-1": "eu",
    "639-2": "eus",
    "639-2/B": "baq",
    family: "Language isolate",
    name: "Basque",
    nativeName: "euskara, euskera",
    wikiUrl: "https://en.wikipedia.org/wiki/Basque_language",
  },
  fa: {
    "639-1": "fa",
    "639-2": "fas",
    "639-2/B": "per",
    family: "Indo-European",
    name: "Persian (Farsi)",
    nativeName: "فارسی",
    wikiUrl: "https://en.wikipedia.org/wiki/Persian_language",
  },
  ff: {
    "639-1": "ff",
    "639-2": "ful",
    family: "Niger–Congo",
    name: "Fula, Fulah, Pulaar, Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
    wikiUrl: "https://en.wikipedia.org/wiki/Fula_language",
  },
  fi: {
    "639-1": "fi",
    "639-2": "fin",
    family: "Uralic",
    name: "Finnish",
    nativeName: "suomi, suomen kieli",
    wikiUrl: "https://en.wikipedia.org/wiki/Finnish_language",
  },
  fj: {
    "639-1": "fj",
    "639-2": "fij",
    family: "Austronesian",
    name: "Fijian",
    nativeName: "vosa Vakaviti",
    wikiUrl: "https://en.wikipedia.org/wiki/Fijian_language",
  },
  fo: {
    "639-1": "fo",
    "639-2": "fao",
    family: "Indo-European",
    name: "Faroese",
    nativeName: "føroyskt",
    wikiUrl: "https://en.wikipedia.org/wiki/Faroese_language",
  },
  fr: {
    "639-1": "fr",
    "639-2": "fra",
    "639-2/B": "fre",
    family: "Indo-European",
    name: "French",
    nativeName: "français, langue française",
    wikiUrl: "https://en.wikipedia.org/wiki/French_language",
  },
  fy: {
    "639-1": "fy",
    "639-2": "fry",
    family: "Indo-European",
    name: "Western Frisian",
    nativeName: "Frysk",
    wikiUrl: "https://en.wikipedia.org/wiki/West_Frisian_language",
  },
  ga: {
    "639-1": "ga",
    "639-2": "gle",
    family: "Indo-European",
    name: "Irish",
    nativeName: "Gaeilge",
    wikiUrl: "https://en.wikipedia.org/wiki/Irish_language",
  },
  gd: {
    "639-1": "gd",
    "639-2": "gla",
    family: "Indo-European",
    name: "Scottish Gaelic, Gaelic",
    nativeName: "Gàidhlig",
    wikiUrl: "https://en.wikipedia.org/wiki/Scottish_Gaelic_language",
  },
  gl: {
    "639-1": "gl",
    "639-2": "glg",
    family: "Indo-European",
    name: "Galician",
    nativeName: "galego",
    wikiUrl: "https://en.wikipedia.org/wiki/Galician_language",
  },
  gn: {
    "639-1": "gn",
    "639-2": "grn",
    family: "Tupian",
    name: "Guaraní",
    nativeName: "Avañe'ẽ",
    wikiUrl: "https://en.wikipedia.org/wiki/Guaran%C3%AD_language",
  },
  gu: {
    "639-1": "gu",
    "639-2": "guj",
    family: "Indo-European",
    name: "Gujarati",
    nativeName: "ગુજરાતી",
    wikiUrl: "https://en.wikipedia.org/wiki/Gujarati_language",
  },
  gv: {
    "639-1": "gv",
    "639-2": "glv",
    family: "Indo-European",
    name: "Manx",
    nativeName: "Gaelg, Gailck",
    wikiUrl: "https://en.wikipedia.org/wiki/Manx_language",
  },
  ha: {
    "639-1": "ha",
    "639-2": "hau",
    family: "Afro-Asiatic",
    name: "Hausa",
    nativeName: "(Hausa) هَوُسَ",
    wikiUrl: "https://en.wikipedia.org/wiki/Hausa_language",
  },
  he: {
    "639-1": "he",
    "639-2": "heb",
    family: "Afro-Asiatic",
    name: "Hebrew (modern)",
    nativeName: "עברית",
    wikiUrl: "https://en.wikipedia.org/wiki/Hebrew_language",
  },
  hi: {
    "639-1": "hi",
    "639-2": "hin",
    family: "Indo-European",
    name: "Hindi",
    nativeName: "हिन्दी, हिंदी",
    wikiUrl: "https://en.wikipedia.org/wiki/Hindi",
  },
  ho: {
    "639-1": "ho",
    "639-2": "hmo",
    family: "Austronesian",
    name: "Hiri Motu",
    nativeName: "Hiri Motu",
    wikiUrl: "https://en.wikipedia.org/wiki/Hiri_Motu_language",
  },
  hr: {
    "639-1": "hr",
    "639-2": "hrv",
    family: "Indo-European",
    name: "Croatian",
    nativeName: "hrvatski jezik",
    wikiUrl: "https://en.wikipedia.org/wiki/Croatian_language",
  },
  ht: {
    "639-1": "ht",
    "639-2": "hat",
    family: "Creole",
    name: "Haitian, Haitian Creole",
    nativeName: "Kreyòl ayisyen",
    wikiUrl: "https://en.wikipedia.org/wiki/Haitian_Creole_language",
  },
  hu: {
    "639-1": "hu",
    "639-2": "hun",
    family: "Uralic",
    name: "Hungarian",
    nativeName: "magyar",
    wikiUrl: "https://en.wikipedia.org/wiki/Hungarian_language",
  },
  hy: {
    "639-1": "hy",
    "639-2": "hye",
    "639-2/B": "arm",
    family: "Indo-European",
    name: "Armenian",
    nativeName: "Հայերեն",
    wikiUrl: "https://en.wikipedia.org/wiki/Armenian_language",
  },
  hz: {
    "639-1": "hz",
    "639-2": "her",
    family: "Niger–Congo",
    name: "Herero",
    nativeName: "Otjiherero",
    wikiUrl: "https://en.wikipedia.org/wiki/Herero_language",
  },
  ia: {
    "639-1": "ia",
    "639-2": "ina",
    family: "Constructed",
    name: "Interlingua",
    nativeName: "Interlingua",
    wikiUrl: "https://en.wikipedia.org/wiki/Interlingua",
  },
  id: {
    "639-1": "id",
    "639-2": "ind",
    family: "Austronesian",
    name: "Indonesian",
    nativeName: "Bahasa Indonesia",
    wikiUrl: "https://en.wikipedia.org/wiki/Indonesian_language",
  },
  ie: {
    "639-1": "ie",
    "639-2": "ile",
    family: "Constructed",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
    wikiUrl: "https://en.wikipedia.org/wiki/Interlingue_language",
  },
  ig: {
    "639-1": "ig",
    "639-2": "ibo",
    family: "Niger–Congo",
    name: "Igbo",
    nativeName: "Asụsụ Igbo",
    wikiUrl: "https://en.wikipedia.org/wiki/Igbo_language",
  },
  ii: {
    "639-1": "ii",
    "639-2": "iii",
    family: "Sino-Tibetan",
    name: "Nuosu",
    nativeName: "ꆈꌠ꒿ Nuosuhxop",
    wikiUrl: "https://en.wikipedia.org/wiki/Nuosu_language",
  },
  ik: {
    "639-1": "ik",
    "639-2": "ipk",
    family: "Eskimo–Aleut",
    name: "Inupiaq",
    nativeName: "Iñupiaq, Iñupiatun",
    wikiUrl: "https://en.wikipedia.org/wiki/Inupiaq_language",
  },
  io: {
    "639-1": "io",
    "639-2": "ido",
    family: "Constructed",
    name: "Ido",
    nativeName: "Ido",
    wikiUrl: "https://en.wikipedia.org/wiki/Ido_(language)",
  },
  is: {
    "639-1": "is",
    "639-2": "isl",
    "639-2/B": "ice",
    family: "Indo-European",
    name: "Icelandic",
    nativeName: "Íslenska",
    wikiUrl: "https://en.wikipedia.org/wiki/Icelandic_language",
  },
  it: {
    "639-1": "it",
    "639-2": "ita",
    family: "Indo-European",
    name: "Italian",
    nativeName: "Italiano",
    wikiUrl: "https://en.wikipedia.org/wiki/Italian_language",
  },
  iu: {
    "639-1": "iu",
    "639-2": "iku",
    family: "Eskimo–Aleut",
    name: "Inuktitut",
    nativeName: "ᐃᓄᒃᑎᑐᑦ",
    wikiUrl: "https://en.wikipedia.org/wiki/Inuktitut",
  },
  ja: {
    "639-1": "ja",
    "639-2": "jpn",
    family: "Japonic",
    name: "Japanese",
    nativeName: "日本語 (にほんご)",
    wikiUrl: "https://en.wikipedia.org/wiki/Japanese_language",
  },
  jv: {
    "639-1": "jv",
    "639-2": "jav",
    family: "Austronesian",
    name: "Javanese",
    nativeName: "ꦧꦱꦗꦮ, Basa Jawa",
    wikiUrl: "https://en.wikipedia.org/wiki/Javanese_language",
  },
  ka: {
    "639-1": "ka",
    "639-2": "kat",
    "639-2/B": "geo",
    family: "South Caucasian",
    name: "Georgian",
    nativeName: "ქართული",
    wikiUrl: "https://en.wikipedia.org/wiki/Georgian_language",
  },
  kg: {
    "639-1": "kg",
    "639-2": "kon",
    family: "Niger–Congo",
    name: "Kongo",
    nativeName: "Kikongo",
    wikiUrl: "https://en.wikipedia.org/wiki/Kongo_language",
  },
  ki: {
    "639-1": "ki",
    "639-2": "kik",
    family: "Niger–Congo",
    name: "Kikuyu, Gikuyu",
    nativeName: "Gĩkũyũ",
    wikiUrl: "https://en.wikipedia.org/wiki/Gikuyu_language",
  },
  kj: {
    "639-1": "kj",
    "639-2": "kua",
    family: "Niger–Congo",
    name: "Kwanyama, Kuanyama",
    nativeName: "Kuanyama",
    wikiUrl: "https://en.wikipedia.org/wiki/Kwanyama",
  },
  kk: {
    "639-1": "kk",
    "639-2": "kaz",
    family: "Turkic",
    name: "Kazakh",
    nativeName: "қазақ тілі",
    wikiUrl: "https://en.wikipedia.org/wiki/Kazakh_language",
  },
  kl: {
    "639-1": "kl",
    "639-2": "kal",
    family: "Eskimo–Aleut",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
    wikiUrl: "https://en.wikipedia.org/wiki/Kalaallisut_language",
  },
  km: {
    "639-1": "km",
    "639-2": "khm",
    family: "Austroasiatic",
    name: "Khmer",
    nativeName: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
    wikiUrl: "https://en.wikipedia.org/wiki/Khmer_language",
  },
  kn: {
    "639-1": "kn",
    "639-2": "kan",
    family: "Dravidian",
    name: "Kannada",
    nativeName: "ಕನ್ನಡ",
    wikiUrl: "https://en.wikipedia.org/wiki/Kannada_language",
  },
  ko: {
    "639-1": "ko",
    "639-2": "kor",
    family: "Koreanic",
    name: "Korean",
    nativeName: "한국어",
    wikiUrl: "https://en.wikipedia.org/wiki/Korean_language",
  },
  kr: {
    "639-1": "kr",
    "639-2": "kau",
    family: "Nilo-Saharan",
    name: "Kanuri",
    nativeName: "Kanuri",
    wikiUrl: "https://en.wikipedia.org/wiki/Kanuri_language",
  },
  ks: {
    "639-1": "ks",
    "639-2": "kas",
    family: "Indo-European",
    name: "Kashmiri",
    nativeName: "कश्मीरी, كشميري‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Kashmiri_language",
  },
  ku: {
    "639-1": "ku",
    "639-2": "kur",
    family: "Indo-European",
    name: "Kurdish",
    nativeName: "Kurdî, كوردی‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Kurdish_language",
  },
  kv: {
    "639-1": "kv",
    "639-2": "kom",
    family: "Uralic",
    name: "Komi",
    nativeName: "коми кыв",
    wikiUrl: "https://en.wikipedia.org/wiki/Komi_language",
  },
  kw: {
    "639-1": "kw",
    "639-2": "cor",
    family: "Indo-European",
    name: "Cornish",
    nativeName: "Kernewek",
    wikiUrl: "https://en.wikipedia.org/wiki/Cornish_language",
  },
  ky: {
    "639-1": "ky",
    "639-2": "kir",
    family: "Turkic",
    name: "Kyrgyz",
    nativeName: "Кыргызча, Кыргыз тили",
    wikiUrl: "https://en.wikipedia.org/wiki/Kyrgyz_language",
  },
  la: {
    "639-1": "la",
    "639-2": "lat",
    family: "Indo-European",
    name: "Latin",
    nativeName: "latine, lingua latina",
    wikiUrl: "https://en.wikipedia.org/wiki/Latin",
  },
  lb: {
    "639-1": "lb",
    "639-2": "ltz",
    family: "Indo-European",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
    wikiUrl: "https://en.wikipedia.org/wiki/Luxembourgish_language",
  },
  lg: {
    "639-1": "lg",
    "639-2": "lug",
    family: "Niger–Congo",
    name: "Ganda",
    nativeName: "Luganda",
    wikiUrl: "https://en.wikipedia.org/wiki/Luganda",
  },
  li: {
    "639-1": "li",
    "639-2": "lim",
    family: "Indo-European",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
    wikiUrl: "https://en.wikipedia.org/wiki/Limburgish_language",
  },
  ln: {
    "639-1": "ln",
    "639-2": "lin",
    family: "Niger–Congo",
    name: "Lingala",
    nativeName: "Lingála",
    wikiUrl: "https://en.wikipedia.org/wiki/Lingala_language",
  },
  lo: {
    "639-1": "lo",
    "639-2": "lao",
    family: "Tai–Kadai",
    name: "Lao",
    nativeName: "ພາສາລາວ",
    wikiUrl: "https://en.wikipedia.org/wiki/Lao_language",
  },
  lt: {
    "639-1": "lt",
    "639-2": "lit",
    family: "Indo-European",
    name: "Lithuanian",
    nativeName: "lietuvių kalba",
    wikiUrl: "https://en.wikipedia.org/wiki/Lithuanian_language",
  },
  lu: {
    "639-1": "lu",
    "639-2": "lub",
    family: "Niger–Congo",
    name: "Luba-Katanga",
    nativeName: "Tshiluba",
    wikiUrl: "https://en.wikipedia.org/wiki/Tshiluba_language",
  },
  lv: {
    "639-1": "lv",
    "639-2": "lav",
    family: "Indo-European",
    name: "Latvian",
    nativeName: "latviešu valoda",
    wikiUrl: "https://en.wikipedia.org/wiki/Latvian_language",
  },
  mg: {
    "639-1": "mg",
    "639-2": "mlg",
    family: "Austronesian",
    name: "Malagasy",
    nativeName: "fiteny malagasy",
    wikiUrl: "https://en.wikipedia.org/wiki/Malagasy_language",
  },
  mh: {
    "639-1": "mh",
    "639-2": "mah",
    family: "Austronesian",
    name: "Marshallese",
    nativeName: "Kajin M̧ajeļ",
    wikiUrl: "https://en.wikipedia.org/wiki/Marshallese_language",
  },
  mi: {
    "639-1": "mi",
    "639-2": "mri",
    "639-2/B": "mao",
    family: "Austronesian",
    name: "Māori",
    nativeName: "te reo Māori",
    wikiUrl: "https://en.wikipedia.org/wiki/M%C4%81ori_language",
  },
  mk: {
    "639-1": "mk",
    "639-2": "mkd",
    "639-2/B": "mac",
    family: "Indo-European",
    name: "Macedonian",
    nativeName: "македонски јазик",
    wikiUrl: "https://en.wikipedia.org/wiki/Macedonian_language",
  },
  ml: {
    "639-1": "ml",
    "639-2": "mal",
    family: "Dravidian",
    name: "Malayalam",
    nativeName: "മലയാളം",
    wikiUrl: "https://en.wikipedia.org/wiki/Malayalam_language",
  },
  mn: {
    "639-1": "mn",
    "639-2": "mon",
    family: "Mongolic",
    name: "Mongolian",
    nativeName: "Монгол хэл",
    wikiUrl: "https://en.wikipedia.org/wiki/Mongolian_language",
  },
  mr: {
    "639-1": "mr",
    "639-2": "mar",
    family: "Indo-European",
    name: "Marathi (Marāṭhī)",
    nativeName: "मराठी",
    wikiUrl: "https://en.wikipedia.org/wiki/Marathi_language",
  },
  ms: {
    "639-1": "ms",
    "639-2": "msa",
    "639-2/B": "may",
    family: "Austronesian",
    name: "Malay",
    nativeName: "bahasa Melayu, بهاس ملايو‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Malay_language",
  },
  mt: {
    "639-1": "mt",
    "639-2": "mlt",
    family: "Afro-Asiatic",
    name: "Maltese",
    nativeName: "Malti",
    wikiUrl: "https://en.wikipedia.org/wiki/Maltese_language",
  },
  my: {
    "639-1": "my",
    "639-2": "mya",
    "639-2/B": "bur",
    family: "Sino-Tibetan",
    name: "Burmese",
    nativeName: "ဗမာစာ",
    wikiUrl: "https://en.wikipedia.org/wiki/Burmese_language",
  },
  na: {
    "639-1": "na",
    "639-2": "nau",
    family: "Austronesian",
    name: "Nauruan",
    nativeName: "Dorerin Naoero",
    wikiUrl: "https://en.wikipedia.org/wiki/Nauruan_language",
  },
  nb: {
    "639-1": "nb",
    "639-2": "nob",
    family: "Indo-European",
    name: "Norwegian Bokmål",
    nativeName: "Norsk bokmål",
    wikiUrl: "https://en.wikipedia.org/wiki/Bokm%C3%A5l",
  },
  nd: {
    "639-1": "nd",
    "639-2": "nde",
    family: "Niger–Congo",
    name: "Northern Ndebele",
    nativeName: "isiNdebele",
    wikiUrl: "https://en.wikipedia.org/wiki/Northern_Ndebele_language",
  },
  ne: {
    "639-1": "ne",
    "639-2": "nep",
    family: "Indo-European",
    name: "Nepali",
    nativeName: "नेपाली",
    wikiUrl: "https://en.wikipedia.org/wiki/Nepali_language",
  },
  ng: {
    "639-1": "ng",
    "639-2": "ndo",
    family: "Niger–Congo",
    name: "Ndonga",
    nativeName: "Owambo",
    wikiUrl: "https://en.wikipedia.org/wiki/Ndonga",
  },
  nl: {
    "639-1": "nl",
    "639-2": "nld",
    "639-2/B": "dut",
    family: "Indo-European",
    name: "Dutch",
    nativeName: "Nederlands, Vlaams",
    wikiUrl: "https://en.wikipedia.org/wiki/Dutch_language",
  },
  nn: {
    "639-1": "nn",
    "639-2": "nno",
    family: "Indo-European",
    name: "Norwegian Nynorsk",
    nativeName: "Norsk nynorsk",
    wikiUrl: "https://en.wikipedia.org/wiki/Nynorsk",
  },
  no: {
    "639-1": "no",
    "639-2": "nor",
    family: "Indo-European",
    name: "Norwegian",
    nativeName: "Norsk",
    wikiUrl: "https://en.wikipedia.org/wiki/Norwegian_language",
  },
  nr: {
    "639-1": "nr",
    "639-2": "nbl",
    family: "Niger–Congo",
    name: "Southern Ndebele",
    nativeName: "isiNdebele",
    wikiUrl: "https://en.wikipedia.org/wiki/Southern_Ndebele_language",
  },
  nv: {
    "639-1": "nv",
    "639-2": "nav",
    family: "Dené–Yeniseian",
    name: "Navajo, Navaho",
    nativeName: "Diné bizaad",
    wikiUrl: "https://en.wikipedia.org/wiki/Navajo_language",
  },
  ny: {
    "639-1": "ny",
    "639-2": "nya",
    family: "Niger–Congo",
    name: "Chichewa, Chewa, Nyanja",
    nativeName: "chiCheŵa, chinyanja",
    wikiUrl: "https://en.wikipedia.org/wiki/Chichewa_language",
  },
  oc: {
    "639-1": "oc",
    "639-2": "oci",
    family: "Indo-European",
    name: "Occitan",
    nativeName: "occitan, lenga d'òc",
    wikiUrl: "https://en.wikipedia.org/wiki/Occitan_language",
  },
  oj: {
    "639-1": "oj",
    "639-2": "oji",
    family: "Algonquian",
    name: "Ojibwe, Ojibwa",
    nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
    wikiUrl: "https://en.wikipedia.org/wiki/Ojibwe_language",
  },
  om: {
    "639-1": "om",
    "639-2": "orm",
    family: "Afro-Asiatic",
    name: "Oromo",
    nativeName: "Afaan Oromoo",
    wikiUrl: "https://en.wikipedia.org/wiki/Oromo_language",
  },
  or: {
    "639-1": "or",
    "639-2": "ori",
    family: "Indo-European",
    name: "Oriya",
    nativeName: "ଓଡ଼ିଆ",
    wikiUrl: "https://en.wikipedia.org/wiki/Oriya_language",
  },
  os: {
    "639-1": "os",
    "639-2": "oss",
    family: "Indo-European",
    name: "Ossetian, Ossetic",
    nativeName: "ирон æвзаг",
    wikiUrl: "https://en.wikipedia.org/wiki/Ossetic_language",
  },
  pa: {
    "639-1": "pa",
    "639-2": "pan",
    family: "Indo-European",
    name: "Punjabi (Eastern)",
    nativeName: "ਪੰਜਾਬੀ",
    wikiUrl: "https://en.wikipedia.org/wiki/Eastern_Punjabi_language",
  },
  pi: {
    "639-1": "pi",
    "639-2": "pli",
    family: "Indo-European",
    name: "Pāli",
    nativeName: "पाऴि",
    wikiUrl: "https://en.wikipedia.org/wiki/P%C4%81li_language",
  },
  pl: {
    "639-1": "pl",
    "639-2": "pol",
    family: "Indo-European",
    name: "Polish",
    nativeName: "język polski, polszczyzna",
    wikiUrl: "https://en.wikipedia.org/wiki/Polish_language",
  },
  ps: {
    "639-1": "ps",
    "639-2": "pus",
    family: "Indo-European",
    name: "Pashto, Pushto",
    nativeName: "پښتو",
    wikiUrl: "https://en.wikipedia.org/wiki/Pashto_language",
  },
  pt: {
    "639-1": "pt",
    "639-2": "por",
    family: "Indo-European",
    name: "Portuguese",
    nativeName: "Português",
    wikiUrl: "https://en.wikipedia.org/wiki/Portuguese_language",
  },
  qu: {
    "639-1": "qu",
    "639-2": "que",
    family: "Quechuan",
    name: "Quechua",
    nativeName: "Runa Simi, Kichwa",
    wikiUrl: "https://en.wikipedia.org/wiki/Quechua_language",
  },
  rm: {
    "639-1": "rm",
    "639-2": "roh",
    family: "Indo-European",
    name: "Romansh",
    nativeName: "rumantsch grischun",
    wikiUrl: "https://en.wikipedia.org/wiki/Romansh_language",
  },
  rn: {
    "639-1": "rn",
    "639-2": "run",
    family: "Niger–Congo",
    name: "Kirundi",
    nativeName: "Ikirundi",
    wikiUrl: "https://en.wikipedia.org/wiki/Kirundi",
  },
  ro: {
    "639-1": "ro",
    "639-2": "ron",
    "639-2/B": "rum",
    family: "Indo-European",
    name: "Romanian",
    nativeName: "Română",
    wikiUrl: "https://en.wikipedia.org/wiki/Romanian_language",
  },
  ru: {
    "639-1": "ru",
    "639-2": "rus",
    family: "Indo-European",
    name: "Russian",
    nativeName: "Русский",
    wikiUrl: "https://en.wikipedia.org/wiki/Russian_language",
  },
  rw: {
    "639-1": "rw",
    "639-2": "kin",
    family: "Niger–Congo",
    name: "Kinyarwanda",
    nativeName: "Ikinyarwanda",
    wikiUrl: "https://en.wikipedia.org/wiki/Kinyarwanda",
  },
  sa: {
    "639-1": "sa",
    "639-2": "san",
    family: "Indo-European",
    name: "Sanskrit (Saṁskṛta)",
    nativeName: "संस्कृतम्",
    wikiUrl: "https://en.wikipedia.org/wiki/Sanskrit",
  },
  sc: {
    "639-1": "sc",
    "639-2": "srd",
    family: "Indo-European",
    name: "Sardinian",
    nativeName: "sardu",
    wikiUrl: "https://en.wikipedia.org/wiki/Sardinian_language",
  },
  sd: {
    "639-1": "sd",
    "639-2": "snd",
    family: "Indo-European",
    name: "Sindhi",
    nativeName: "सिन्धी, سنڌي، سندھی‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Sindhi_language",
  },
  se: {
    "639-1": "se",
    "639-2": "sme",
    family: "Uralic",
    name: "Northern Sami",
    nativeName: "Davvisámegiella",
    wikiUrl: "https://en.wikipedia.org/wiki/Northern_Sami",
  },
  sg: {
    "639-1": "sg",
    "639-2": "sag",
    family: "Creole",
    name: "Sango",
    nativeName: "yângâ tî sängö",
    wikiUrl: "https://en.wikipedia.org/wiki/Sango_language",
  },
  si: {
    "639-1": "si",
    "639-2": "sin",
    family: "Indo-European",
    name: "Sinhalese, Sinhala",
    nativeName: "සිංහල",
    wikiUrl: "https://en.wikipedia.org/wiki/Sinhalese_language",
  },
  sk: {
    "639-1": "sk",
    "639-2": "slk",
    "639-2/B": "slo",
    family: "Indo-European",
    name: "Slovak",
    nativeName: "slovenčina, slovenský jazyk",
    wikiUrl: "https://en.wikipedia.org/wiki/Slovak_language",
  },
  sl: {
    "639-1": "sl",
    "639-2": "slv",
    family: "Indo-European",
    name: "Slovene",
    nativeName: "slovenski jezik, slovenščina",
    wikiUrl: "https://en.wikipedia.org/wiki/Slovene_language",
  },
  sm: {
    "639-1": "sm",
    "639-2": "smo",
    family: "Austronesian",
    name: "Samoan",
    nativeName: "gagana fa'a Samoa",
    wikiUrl: "https://en.wikipedia.org/wiki/Samoan_language",
  },
  sn: {
    "639-1": "sn",
    "639-2": "sna",
    family: "Niger–Congo",
    name: "Shona",
    nativeName: "chiShona",
    wikiUrl: "https://en.wikipedia.org/wiki/Shona_language",
  },
  so: {
    "639-1": "so",
    "639-2": "som",
    family: "Afro-Asiatic",
    name: "Somali",
    nativeName: "Soomaaliga, af Soomaali",
    wikiUrl: "https://en.wikipedia.org/wiki/Somali_language",
  },
  sq: {
    "639-1": "sq",
    "639-2": "sqi",
    "639-2/B": "alb",
    family: "Indo-European",
    name: "Albanian",
    nativeName: "Shqip",
    wikiUrl: "https://en.wikipedia.org/wiki/Albanian_language",
  },
  sr: {
    "639-1": "sr",
    "639-2": "srp",
    family: "Indo-European",
    name: "Serbian",
    nativeName: "српски језик",
    wikiUrl: "https://en.wikipedia.org/wiki/Serbian_language",
  },
  ss: {
    "639-1": "ss",
    "639-2": "ssw",
    family: "Niger–Congo",
    name: "Swati",
    nativeName: "SiSwati",
    wikiUrl: "https://en.wikipedia.org/wiki/Swati_language",
  },
  st: {
    "639-1": "st",
    "639-2": "sot",
    family: "Niger–Congo",
    name: "Southern Sotho",
    nativeName: "Sesotho",
    wikiUrl: "https://en.wikipedia.org/wiki/Sotho_language",
  },
  su: {
    "639-1": "su",
    "639-2": "sun",
    family: "Austronesian",
    name: "Sundanese",
    nativeName: "Basa Sunda",
    wikiUrl: "https://en.wikipedia.org/wiki/Sundanese_language",
  },
  sv: {
    "639-1": "sv",
    "639-2": "swe",
    family: "Indo-European",
    name: "Swedish",
    nativeName: "svenska",
    wikiUrl: "https://en.wikipedia.org/wiki/Swedish_language",
  },
  sw: {
    "639-1": "sw",
    "639-2": "swa",
    family: "Niger–Congo",
    name: "Swahili",
    nativeName: "Kiswahili",
    wikiUrl: "https://en.wikipedia.org/wiki/Swahili_language",
  },
  ta: {
    "639-1": "ta",
    "639-2": "tam",
    family: "Dravidian",
    name: "Tamil",
    nativeName: "தமிழ்",
    wikiUrl: "https://en.wikipedia.org/wiki/Tamil_language",
  },
  te: {
    "639-1": "te",
    "639-2": "tel",
    family: "Dravidian",
    name: "Telugu",
    nativeName: "తెలుగు",
    wikiUrl: "https://en.wikipedia.org/wiki/Telugu_language",
  },
  tg: {
    "639-1": "tg",
    "639-2": "tgk",
    family: "Indo-European",
    name: "Tajik",
    nativeName: "тоҷикӣ, toçikī, تاجیکی‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Tajik_language",
  },
  th: {
    "639-1": "th",
    "639-2": "tha",
    family: "Tai–Kadai",
    name: "Thai",
    nativeName: "ไทย",
    wikiUrl: "https://en.wikipedia.org/wiki/Thai_language",
  },
  ti: {
    "639-1": "ti",
    "639-2": "tir",
    family: "Afro-Asiatic",
    name: "Tigrinya",
    nativeName: "ትግርኛ",
    wikiUrl: "https://en.wikipedia.org/wiki/Tigrinya_language",
  },
  tk: {
    "639-1": "tk",
    "639-2": "tuk",
    family: "Turkic",
    name: "Turkmen",
    nativeName: "Türkmen, Түркмен",
    wikiUrl: "https://en.wikipedia.org/wiki/Turkmen_language",
  },
  tl: {
    "639-1": "tl",
    "639-2": "tgl",
    family: "Austronesian",
    name: "Tagalog",
    nativeName: "Wikang Tagalog",
    wikiUrl: "https://en.wikipedia.org/wiki/Tagalog_language",
  },
  tn: {
    "639-1": "tn",
    "639-2": "tsn",
    family: "Niger–Congo",
    name: "Tswana",
    nativeName: "Setswana",
    wikiUrl: "https://en.wikipedia.org/wiki/Tswana_language",
  },
  to: {
    "639-1": "to",
    "639-2": "ton",
    family: "Austronesian",
    name: "Tonga (Tonga Islands)",
    nativeName: "faka Tonga",
    wikiUrl: "https://en.wikipedia.org/wiki/Tongan_language",
  },
  tr: {
    "639-1": "tr",
    "639-2": "tur",
    family: "Turkic",
    name: "Turkish",
    nativeName: "Türkçe",
    wikiUrl: "https://en.wikipedia.org/wiki/Turkish_language",
  },
  ts: {
    "639-1": "ts",
    "639-2": "tso",
    family: "Niger–Congo",
    name: "Tsonga",
    nativeName: "Xitsonga",
    wikiUrl: "https://en.wikipedia.org/wiki/Tsonga_language",
  },
  tt: {
    "639-1": "tt",
    "639-2": "tat",
    family: "Turkic",
    name: "Tatar",
    nativeName: "татар теле, tatar tele",
    wikiUrl: "https://en.wikipedia.org/wiki/Tatar_language",
  },
  tw: {
    "639-1": "tw",
    "639-2": "twi",
    family: "Niger–Congo",
    name: "Twi",
    nativeName: "Twi",
    wikiUrl: "https://en.wikipedia.org/wiki/Twi",
  },
  ty: {
    "639-1": "ty",
    "639-2": "tah",
    family: "Austronesian",
    name: "Tahitian",
    nativeName: "Reo Tahiti",
    wikiUrl: "https://en.wikipedia.org/wiki/Tahitian_language",
  },
  ug: {
    "639-1": "ug",
    "639-2": "uig",
    family: "Turkic",
    name: "Uyghur",
    nativeName: "ئۇيغۇرچە‎, Uyghurche",
    wikiUrl: "https://en.wikipedia.org/wiki/Uyghur_language",
  },
  uk: {
    "639-1": "uk",
    "639-2": "ukr",
    family: "Indo-European",
    name: "Ukrainian",
    nativeName: "Українська",
    wikiUrl: "https://en.wikipedia.org/wiki/Ukrainian_language",
  },
  ur: {
    "639-1": "ur",
    "639-2": "urd",
    family: "Indo-European",
    name: "Urdu",
    nativeName: "اردو",
    wikiUrl: "https://en.wikipedia.org/wiki/Urdu",
  },
  uz: {
    "639-1": "uz",
    "639-2": "uzb",
    family: "Turkic",
    name: "Uzbek",
    nativeName: "Oʻzbek, Ўзбек, أۇزبېك‎",
    wikiUrl: "https://en.wikipedia.org/wiki/Uzbek_language",
  },
  ve: {
    "639-1": "ve",
    "639-2": "ven",
    family: "Niger–Congo",
    name: "Venda",
    nativeName: "Tshivenḓa",
    wikiUrl: "https://en.wikipedia.org/wiki/Venda_language",
  },
  vi: {
    "639-1": "vi",
    "639-2": "vie",
    family: "Austroasiatic",
    name: "Vietnamese",
    nativeName: "Tiếng Việt",
    wikiUrl: "https://en.wikipedia.org/wiki/Vietnamese_language",
  },
  vo: {
    "639-1": "vo",
    "639-2": "vol",
    family: "Constructed",
    name: "Volapük",
    nativeName: "Volapük",
    wikiUrl: "https://en.wikipedia.org/wiki/Volap%C3%BCk",
  },
  wa: {
    "639-1": "wa",
    "639-2": "wln",
    family: "Indo-European",
    name: "Walloon",
    nativeName: "walon",
    wikiUrl: "https://en.wikipedia.org/wiki/Walloon_language",
  },
  wo: {
    "639-1": "wo",
    "639-2": "wol",
    family: "Niger–Congo",
    name: "Wolof",
    nativeName: "Wollof",
    wikiUrl: "https://en.wikipedia.org/wiki/Wolof_language",
  },
  xh: {
    "639-1": "xh",
    "639-2": "xho",
    family: "Niger–Congo",
    name: "Xhosa",
    nativeName: "isiXhosa",
    wikiUrl: "https://en.wikipedia.org/wiki/Xhosa_language",
  },
  yi: {
    "639-1": "yi",
    "639-2": "yid",
    family: "Indo-European",
    name: "Yiddish",
    nativeName: "ייִדיש",
    wikiUrl: "https://en.wikipedia.org/wiki/Yiddish_language",
  },
  yo: {
    "639-1": "yo",
    "639-2": "yor",
    family: "Niger–Congo",
    name: "Yoruba",
    nativeName: "Yorùbá",
    wikiUrl: "https://en.wikipedia.org/wiki/Yoruba_language",
  },
  za: {
    "639-1": "za",
    "639-2": "zha",
    family: "Tai–Kadai",
    name: "Zhuang, Chuang",
    nativeName: "Saɯ cueŋƅ, Saw cuengh",
    wikiUrl: "https://en.wikipedia.org/wiki/Zhuang_languages",
  },
  zh: {
    "639-1": "zh",
    "639-2": "zho",
    "639-2/B": "chi",
    family: "Sino-Tibetan",
    name: "Chinese",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
    wikiUrl: "https://en.wikipedia.org/wiki/Chinese_language",
  },
  zu: {
    "639-1": "zu",
    "639-2": "zul",
    family: "Niger–Congo",
    name: "Zulu",
    nativeName: "isiZulu",
    wikiUrl: "https://en.wikipedia.org/wiki/Zulu_language",
  },
};
