// React
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import CheckIn from './CheckIn';
import ReactPWAInstallProvider from 'react-pwa-install';

App.propTypes = {};
export default function App() {
  return (
    <ReactPWAInstallProvider enableLogging>
        <Router>
          <Switch>
            <Route path="/checkin">
              <CheckIn />
            </Route>
            <Route path="*">
              <Redirect to={{ pathname: '/checkin' }} />
            </Route>
          </Switch>
        </Router>
    </ReactPWAInstallProvider>
  );
}
